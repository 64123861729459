import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg2">
          <div className="wrapper">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
            <div className="content">
              <section className="main">
                <div className="text full">
                  <div className="law">
                    <h1>Nota prawna</h1>
                    <p>
                      Dostęp i korzystanie z niniejszej strony internetowej
                      oznacza bezwarunkową akceptację zasad jej użytkowania. W
                      przypadku braku zgody na stosowanie poniższych zasad,
                      użytkownik powinien niezwłocznie zaprzestać korzystania ze
                      strony.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Cała zawartość niniejszej strony internetowej jest
                      własnością i podlega kontroli Johnson &amp; Johnson Poland
                      Sp. z o.o. z siedzibą w Warszawie, pod adresem: 02-135
                      Warszawa, ul. Iłżecka 24, wpisanej do Rejestru
                      Przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st.
                      Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego
                      Rejestru Sądowego pod nr KRS 0000032 278, kapitał
                      zakładowy w wysokości 39.751.500 zł, NIP 113-00-20-467.
                      Wszelkie prawa zastrzeżone. Zawartość niniejszej strony
                      internetowej jest chroniona przez prawa autorskie w Polsce
                      i na całym świecie.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Użytkownik ma prawo kopiować treści umieszczone na
                      niniejszej stronie internetowej tylko i wyłącznie dla
                      osobistego użytku w celach niekomercyjnych. Jakiekolwiek
                      wprowadzanie zmian i dalsze powielanie są zabronione.
                      Zabrania się powielania lub &nbsp;ykorzystywania
                      zawartości strony w każdy inny sposób.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Johnson &amp; Johnson Poland Sp. z o.o. będzie, w miarę
                      możliwości, aktualizować informacje, podawane na
                      niniejszej stronie internetowej, nie udziela jednak
                      gwarancji, że wszystkie materiały są dokładne, aktualne i
                      wyczerpujące w zakresie podawanych informacji.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Johnson &amp; Johnson Poland Sp. z o.o. nie ponosi żadnej
                      odpowiedzialności (za decyzje podejmowane przez
                      użytkowników na podstawie informacji zawartych na stronie,
                      za skutki wykorzystania przez użytkowników informacji
                      uzyskanych ze strony, &nbsp;ezpośrednio lub pośrednio
                      poniesione przez użytkowników lub osoby trzecie straty,
                      szkody, doznaną krzywdę, ani inne negatywne skutki
                      wynikające z dostępu użytkownika do tej strony
                      internetowej lub jego braku.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Niniejsza strona internetowa może zawierać linki lub
                      odnośniki do innych stron. Johnson &amp; Johnson Poland
                      Sp. z o.o. nie jest odpowiedzialne za treść stron, do
                      których te linki/odnośniki prowadzą i nie ponosi
                      odpowiedzialności za zawarte tam treści, ani ich
                      wykorzystanie przez użytkownika. Wszelkie łącza do innych
                      stron podane są wyłącznie dla wygody użytkowników
                      niniejszej strony internetowej.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Wszelkie znaki towarowe, nazwy handlowe, szata graficzna
                      oraz inne prawa własności intelektualnej, takie jak
                      patenty lub wzory przemysłowe, wykorzystane lub
                      przedstawione na niniejszej stronie są zastrzeżone oraz
                      chronione prawem w Polsce i na całym świecie. Zabrania się
                      wykorzystywania ich w jakikolwiek sposób bez uprzedniej
                      zgody Johnson &amp; Johnson Poland Sp. z o.o. wyrażonej w
                      formie pisemnej pod rygorem nieważności.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Żadne informacje przekazywane przez użytkownika strony, w
                      tym pytania, uwagi, sugestie lub podobne informacje
                      dotyczące strony, produktów lub działalności Johnson &amp;
                      Johnson Poland Sp. z o.o., nie będą traktowane jako
                      informacje poufne, a Johnson &amp; &nbsp;Johnson Poland
                      Sp. z o.o. nie będzie posiadać jakichkolwiek zobowiązań w
                      odniesieniu do tego rodzaju informacji („Materiały”). W
                      przypadku zamieszczenia przez użytkownika Materiałów na
                      stronie, użytkownik gwarantuje i oświadcza, że korzystanie
                      z &nbsp;Materiałów przez Johnson &amp; Johnson Poland Sp.
                      z o.o. lub osoby trzecie nie będzie naruszać żadnych praw
                      użytkownika ani praw osób trzecich. Zamieszczając na
                      stronie Materiały lub w inny sposób przekazując je,
                      użytkownik ponosi odpowiedzialność za treść i informacje w
                      nich zawarte, włączając w to ich prawdziwość i dokładność.
                      Z chwilą zamieszczenia Materiałów na stronie, użytkownik
                      udziela Johnson &amp; Johnson Poland Sp. z o.o. oraz
                      spółkom z korporacji Johnson &amp; Johnson nieodpłatnej
                      licencji do korzystania z Materiałów w nieograniczonym
                      czasie i przestrzeni na następujących polach eksploatacji:
                      utrwalanie lub zwielokrotniania dowolną techniką, w tym
                      techniką drukarską, reprograficzną, zapisu magnetycznego
                      oraz techniką cyfrową, wprowadzanie do obrotu w kraju lub
                      za granicą, użyczanie lub najem oryginału lub egzemplarzy,
                      rozpowszechnianie utworu, w tym jego publiczne wykonanie,
                      wystawianie, wyświetlanie, odtwarzanie oraz nadawanie i
                      reemitowanie w każdy sposób i każdą techniką, a także
                      publiczne udostępnianie w taki sposób, aby każdy mógł mieć
                      do niego dostęp w miejscu i czasie przez siebie wybranym.
                      Równocześnie użytkownik udziela Johnson &amp; Johnson
                      Poland Sp. z o.o. i spółkom z korporacji Johnson &amp;
                      Johnson prawa do wykonywania i zezwalania osobom trzecim
                      na wykonywanie autorskich praw zależnych i autorskich praw
                      osobistych do utworów zawartych w Materiale oraz do
                      korzystania z takich utworów bez podawania nazwiska
                      twórcy. Użytkownik udziela zgody na korzystanie przez
                      Johnson &amp; Johnson Poland Sp. z o.o. i spółki z
                      korporacji Johnson &amp; Johnson przez nieograniczony czas
                      ze wszelkich informacji, pomysłów, koncepcji, know-how i
                      technik zawartych w Materiałach w dowolnym celu, w tym
                      między innymi w celu opracowywania, wytwarzania i
                      wprowadzania do obrotu produktów wykorzystujących tego
                      rodzaju informacje oraz w materiałach promocyjnych Johnson
                      &amp; Johnson Poland Sp. z o.o. i spółek z korporacji
                      Johnson &amp; Johnson. Z tego tytułu, jak również z tytułu
                      udzielenia licencji określonej powyżej, użytkownikowi nie
                      będzie przysługiwało prawo do wynagrodzenia.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Wszelkie dane osobowe przekazywane za pomocą niniejszej
                      strony internetowej przetwarzane są na zasadach
                      określonych w{" "}
                      <a href="/site/polityka-prywatnosci/">
                        Polityce Prywatności
                      </a>
                      .
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Johnson &amp; Johnson Poland zastrzega sobie prawo do
                      usuwania, zmiany lub powiększania zawartości tej strony w
                      jakimkolwiek czasie i&nbsp;z&nbsp;jakiegokolwiek powodu,
                      bez uprzedniego powiadomienia.
                    </p>
                    <p>&nbsp;</p>
                    <p>Strona przeznaczona jest dla mieszkańców Polski.</p>
                  </div>
                </div>
              </section>
            </div>
            <div id="social_id_21"></div>
          </div>
        </div>
        <footer>
          <div className="wrapper">
            <nav>
              <a href="/site/" className="logo2" />
              <a href="/site/nota-prawna/">Nota Prawna</a>
              <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
               <a href="/site/cookies/">Polityka cookies</a>
              <a href="/site/kontakt/">Zachęcamy do kontaktu</a>
                           <span>© Johnson &amp; Johnson Poland Sp. z o.o. 2013 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
            <p>
              Strona zarządzana jest przez Johnson &amp; Johnson Poland Sp. z o.
              o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
              Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
              Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
              113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
              Johnson &amp; Johnson Poland Sp. z o. o. ponosi wyłączną
              odpowiedzialność za treści zawarte na niniejszej stronie.
              <br /> Strona jest przeznaczona dla użytkowników z Polski.
              Ostatnia aktualizacja: 25/10/2013
              <br />{" "}
            </p>{" "}
            <p>
              <span>
                Strona została zoptymalizowana pod kątem przeglądarek: Internet
                Explorer 7 i wyższych, Mozilla Firefox, Google Chrome, Opera,
                Safari
              </span>{" "}
              <span className="right">
                Zalecana rozdzielczość: 1024 x 768 pikseli
              </span>{" "}
            </p>
          </div>
        </footer>
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
          }}
        />
        
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
          }}
        />
      </div>
    );
  }
}

export default Page;
